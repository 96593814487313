import { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import TicketInfo from "../TicketInfo";
import TicketActivityLog from "../TicketActivityLog";

const TicketsDetails = ({
  ticketData,
  isLoading,
  activityData,
  loadActivityData,
  isLoadingLogs
}) => {
  const [file, setFile] = useState("");
  const handleChange = (file) => {
    setFile(file);
  };
  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
    >
      <Grid container spacing={2}>
        <Grid size={8} item>
          {" "}
          <TicketInfo
            file={file}
            handleChange={handleChange}
            pageData={ticketData}
            isLoading={isLoading}
          />
        </Grid>
        <Grid size={4} item>
          {" "}
          <TicketActivityLog
            activityData={activityData}
            isLoading={isLoadingLogs}
            loadActivityData={loadActivityData}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default TicketsDetails;
