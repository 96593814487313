import Grid from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import "./style.css";
import { Skeleton, Typography } from "@mui/material";
import { renderSwitch } from "../../utils/TicketDetailsCommonFunction";
import { getTicketConfigDetails } from "./TicketConfigDetailsFunctions";


const TicketConfigDetails = ({ pageData,isLoading }) => {
  const options = getTicketConfigDetails(pageData);
  if (isLoading) {
    return <Skeleton variant="rectangular" width="100%" height={170} />;
  }
  return (
    <Box sx={{ width: "100%", fontFamily: "Rubik", marginBottom: "1.5rem" }}>
      <Grid container rowSpacing={2} columnSpacing={{ sm: 8, md: 12, lg: 16 }}>
        {options.map((item) => (
          <Grid key={item.id} item size={6} className="options" >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color: "rgba(103, 107, 126, 1)",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <item.icon
                  style={{ marginRight: "0.5rem", fontSize: "1.4rem" }}
                />
                <Typography>{item.name}</Typography>
              </div>
            </div>
            <div
              style={{ width: "60%", fontSize: "0.9rem", fontFamily: "Rubik" }}
            >
              {renderSwitch(item.type, item.value, item.listData)}
            </div>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default TicketConfigDetails;
