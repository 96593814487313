import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

export const updatedObject = (originalObject) =>
  Object.entries(originalObject).reduce((acc, [key, value]) => {
    acc[key] = value == null || value == undefined ? "" : value;
    return acc;
  }, {});

export const isSuperUser = () => {
  return localStorage.getItem("is_superuser") === "true";
};

export const isManagerOrSuperUser = () => {
  return (
    localStorage.getItem("is_manager") == "true" ||
    localStorage.getItem("is_superuser") == "true"
  );
};

export const isGuardianUser = () => {
  return !(
    localStorage.getItem("guardian_user_id") == 0 ||
    localStorage.getItem("guardian_user_id") == null ||
    localStorage.getItem("guardian_user_id") == "null"
  );
};

export const startTimeText = "00:00:00";

export const endTimeText = "23:59:00";

export const showAddButton = (page) => {
  if (page === "Teams") return isSuperUser();
  else if (page == "User") return isManagerOrSuperUser();
};

export const redirectToTicketSumary = () => {
  if (!isGuardianUser()) {
    window.location.href = "/tickets";
  } else window.location.href = "/dashboard";
};

export const arrayToUpperCase = (myArray) => {
  if (myArray) {
    return myArray.map((item) => item.toUpperCase());
  }
};

export const arrayToLowerCase = (myArray) => {
  if (myArray) {
    return myArray.map((item) => item.toLowerCase());
  }
};

export const getTicketsUrl = (url, teams) => {
  let teamsQueryParam = "";
  if (isSuperUser()) return url;
  else if (isGuardianUser() && !isSuperUser() && teams) {
    teamsQueryParam = localStorage
      .getItem("teams")
      .split(",")
      .map((team) => `&teams=${team}`)
      .join("")
      .replace("&", "?");
  }
  return `${url}/${teamsQueryParam}`;
};

export const getTicketsLink = (link) => {
  return link.includes("/tickets")
    ? getTicketsUrl("/tickets", localStorage.getItem("teams"))
    : link;
};

export const createHeadCellObject = (id, numeric, disablePadding, label) => {
  return {
    id,
    numeric,
    disablePadding,
    label,
  };
};

export const formatTimeValue = (timeValue) => {
  const hours =
    parseInt(timeValue.$H) < 10 ? `0${timeValue.$H}` : `${timeValue.$H}`;
  const mins =
    parseInt(timeValue.$m) < 10 ? `0${timeValue.$m}` : `${timeValue.$m}`;
  const secs =
    parseInt(timeValue.$s) < 10 ? `0${timeValue.$s}` : `${timeValue.$s}`;

  return `${hours}:${mins}:${secs}`;
};

export const updateDateToHumanFormat = (dateToFormat) => {
  const formattedDate = new Date(dateToFormat);
  const day = formattedDate.getUTCDate().toString().padStart(2, "0");
  const month = (formattedDate.getUTCMonth() + 1).toString().padStart(2, "0");
  const year = formattedDate.getUTCFullYear().toString().slice(-2);
  const hours = formattedDate.getUTCHours().toString().padStart(2, "0");
  const minutes = formattedDate.getUTCMinutes().toString().padStart(2, "0");
  return (
    <div>
      <div>{`${day}/${month}/${year}`}</div>
      <div>{`${hours}:${minutes}`}</div>
    </div>
  );
};

export const getDateParamInUrl = (userSelectedFilter) => {
  const locationObject = new URLSearchParams(window.location.search);
  const dateMapping = {
    today: "Today",
    yesterday: "Yesterday",
    last_7_days_from: "Last 7 days",
    last_14_days_from: "Last 14 days",
    last_30_days_from: "Last 30 days",
    custom_date_from: "Custom date",
    custom_date_to: "Custom date",
  };
  const presentParam = Object.keys(dateMapping).find((param) =>
    locationObject.has(param)
  );

  if (presentParam === undefined || locationObject.size == 0) {
    return userSelectedFilter["spanKey"];
  } else if (presentParam) {
    return dateMapping[presentParam];
  } else {
    return "Last 7 days";
  }
};

export const getMinMaxDate = (dateString, noOfdays) => {
  const date = new Date(dateString);

  date.setDate(date.getDate() + noOfdays);

  return date;
};

const fixedList = [
  "div-20",
  "img-10",
  "span-10",
  "parent_filter_option",
  "filter_dropdown_child_options",
  "searchInput",
  "firstLetter",
  "assignee_sub_filter",
  "sub_filter_checkbox",
  "single_selection_sub_filter",
];

export const handleClickOutsideFilters = (dropdownClassName) => {
  const elem = document.getElementsByClassName(dropdownClassName)[0];

  const handleHideAllFilters = (e) => {
    if (!fixedList.includes(Array.from(e.target.classList)[0])) {
      elem?.classList.remove("show");
    }
  };

  document.addEventListener("click", handleHideAllFilters);

  return () => {
    document.removeEventListener("click", handleHideAllFilters);
  };
};

export const arrowIcons = (arrow_type, color) => {
  const icons = {
    down: <ArrowDownwardIcon sx={{ color }} />,
    up: <ArrowUpwardIcon sx={{ color }} />,
  };

  return icons[arrow_type] || null;
};

export const getTrendText = (trend, previous, current) => {
  if (!trend) return "No change from previous period";

  const changeText = `${Math.abs(trend)}% ${
    trend > 0 ? "increase" : "decrease"
  }`;
  return previous !== undefined && current !== undefined
    ? `${changeText} from previous period (from ${previous} to ${current})`
    : `${changeText} from previous period`;
};

export const getColorStyles = (color) => {
  const styles = {
    red: {
      icon: "#E00000 !important",
      background: "#FFECEC",
      border: "1px solid #FFD7D7",
    },
    green: {
      icon: "#008243 !important",
      background: "#EBF5F0",
      border: "1px solid #B3DAC7",
    },
    grey: {
      icon: "#575A6A !important",
      background: "#F0F0F0",
      border: "1px solid #D3D3D3",
    },
    default: {
      icon: "#000000 !important",
      background: "#FFFFFF",
      border: "1px solid #000000",
    },
  };

  return styles[color] || styles.default;
};

export const getDayNameFromDate = (dateToFormat) => {
  const formattedDate = new Date(dateToFormat);
  const day = formattedDate.getUTCDay();
  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const dayName = dayNames[day];
  return (
    <div>
      <div>{`${dayName}`}</div>
    </div>
  );
};


export const updateDateToHumanFormatAuditLogs = (isoString) => {
  const date = new Date(isoString);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = months[date.getUTCMonth()];
  const day = date.getUTCDate();
  // Adjusting the hours and minutes from UTC to your local time if needed
  let hours = date.getUTCHours();
  let minutes = date.getUTCMinutes();

  // Apply time zone difference if you want it in a different time zone (example converts to user's local timezone)
  const timeZoneOffsetInMinutes = date.getTimezoneOffset();
  const totalMinutes = hours * 60 + minutes - timeZoneOffsetInMinutes;
  hours = Math.floor(totalMinutes / 60);
  minutes = totalMinutes % 60;

  // Formatting hours and minutes
  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");

  return `${month} ${day}, ${formattedHours}:${formattedMinutes}`;
};

export const extractAndCapitalize = (text) => {
  // Extract the part before the @ symbol
  // Capitalize the first letter
  const capitalizedUsername = text.charAt(0).toUpperCase() + text.slice(1);
  return capitalizedUsername;
};

export const extractAndCapitalizeAllWords = (text) => {
  // Split the text into individual words
  const words = text.split(".");
  // Capitalize each word
  const capitalizedWords = words.map((word) => extractAndCapitalize(word));
  // Join the capitalized words back into a single string
  const capitalizedText = capitalizedWords.join(" ");
  return capitalizedText;
};


export const componentDecorator = (href, text, key) => (
  <a
    href={href}
    key={key}
    target="_blank"
    rel="noopener noreferrer"
    style={{ color: "blue" }}
  >
    {text}
  </a>
);