import { useEffect, useRef } from "react";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";

import TicketActivityLogComponents from "../TicketActivityLogComponents";
import TicketCommentMail from "../TicketCommentMail";
import "./style.css";

const TicketActivityLog = ({ activityData, isLoading, loadActivityData }) => {
  const gridRef = useRef(null);
  const hasScrolledToBottomInitially = useRef(false);

  useEffect(() => {
    if (gridRef.current && !hasScrolledToBottomInitially.current) {
      const scrollOptions = {
        behavior: "smooth",
      };
      gridRef.current.scrollTo(scrollOptions);
      gridRef.current.scrollTop = 1000;
    }
  }, [activityData]);

  const handleScroll = () => {
    if (gridRef.current) {
      const { scrollTop } = gridRef.current;
      if (scrollTop <= 100 && !isLoading) {
        loadActivityData();
        hasScrolledToBottomInitially.current = true;
      }
    }
  };

  return (
    <Grid
      className="mainActivityGrid"
      sx={{
        height: "100vh",
        backgroundColor: "rgba(248, 249, 250, 1)",
        padding: "1.5rem",
      }}
    >
      <Grid
        style={{
          display: "flex",
          marginBottom: "1.5rem",
          position: "fixed",
          top: 0,
          background: "rgba(248, 249, 250, 1)",
          width: "100%",
          height: "auto",
          padding: "0.9rem 0.9rem",
        }}
      >
        <Typography
          sx={{
            fontSize: "1.5rem !important",
            fontWeight: 500,
          }}
        >
          Activity log
        </Typography>
      </Grid>

      <Grid ref={gridRef} className="activity_box" onScroll={handleScroll}>
        <TicketActivityLogComponents
          activityData={activityData}
          isLoading={isLoading}
        />
      </Grid>
      <Grid className="fixed-bottom">
        <TicketCommentMail />
      </Grid>
    </Grid>
  );
};

export default TicketActivityLog;
