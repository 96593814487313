import "./style.css";
import { Grid } from "@mui/system";
import { TicketAction, CommentCard } from "../TicketAction";
import { CircularLoader } from "../CircularLoader";

const DisplayBlock = ({ event_type, data }) => {
  if (event_type == "comment_added" || event_type == "comment_updated") {
    return <CommentCard data={data} />;
  }
  return <TicketAction data={data} />;
};

let loadingOrNoDataMessage = null;



const TicketActivityLogComponents = ({ activityData, isLoading }) => {

  if (isLoading) {
    loadingOrNoDataMessage = <CircularLoader />;
  } else if (activityData.length===0) {
    loadingOrNoDataMessage = <p>No data to display</p>;
  }
  else{
    loadingOrNoDataMessage=""
  }

  return (
    <>

      <Grid textAlign="center">{loadingOrNoDataMessage}</Grid>

      {[...activityData].reverse().map((object, index) => (
        <Grid spacing={1} key={index} >
          <DisplayBlock event_type={object["event_type"]} data={object} />
        </Grid>
      ))}
    </>
  );
};

export default TicketActivityLogComponents;
