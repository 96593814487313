import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import TourOutlinedIcon from "@mui/icons-material/TourOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import { checkPriority } from "../utils/TicketsTableFunctions";
import LanguageIcon from "@mui/icons-material/Language";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";

import { TextField, Typography } from "@mui/material";
import UserIcon from "../components/UserIcon";
import { Grid } from "@mui/system";
import { ChipWithMenu } from "../components/TeamsCountChips";
import { capitalizeFirstLetter } from "./clearAllSelectedFilters";
import sanitizeHtml from "sanitize-html";

const tagChips = (value) => {
  const displayValues = value.slice(0, 2);
  const remainingValues = value.slice(2);
  return (
    <Grid container spacing={1}>
      {displayValues.map((key, index) => (
        <Grid item key={index} className="ticket_tag">
          {key}
        </Grid>
      ))}
      {remainingValues.length > 0 ? (
        <ChipWithMenu
          menuItemList={remainingValues}
          count={remainingValues.length}
        />
      ) : (
        <></>
      )}
    </Grid>
  );
};

export const renderSwitch = (type, value, listData) => {
  if (!value) {
    return (
      <p
        style={{
          color: "rgba(155, 159, 177, 1)",
        }}
      >
        Empty
      </p>
    );
  }

  switch (type) {
    case "dropdown":
      return (
        <TextField
          data-testid="select"
          // select
          id="outlined-basic"
          variant="outlined"
          sx={{
            width: "100%",

            "& .MuiSelect-select span::before": {
              content: `"Select list"`,
              color: "#83889E",
            },
            "& .MuiOutlinedInput-root": {
              maxHeight: "32px !important",
              fontSize: "14px",
              fontWeight: 600,
              color: "#3c3e49",
              width: "8rem",
            },
          }}
          value={value}
          // onChange={handleInputChange}
          fullWidth
        ></TextField>
      );
    case "assignee":
      return (
        <UserIcon
          image_url={listData[0]["url"]}
          username={listData[0]["username"]}
          colorCode={listData[0]["color_code"]}
        />
      );
    case "text":
      return <Typography>{value}</Typography>;
    case "priority":
      return checkPriority(value, true);
    case "tag":
      return tagChips(value);
    case "status":
    case "assignee_added":
    case "assignee_removed":
    case "assignee_changed":
    case "custom_field_updated":
      return <Typography>{capitalizeFirstLetter(value)}</Typography>;

    default:
      return <Typography>Empty</Typography>;
  }
};

export const iconsMap = {
  status_changed: <RadioButtonCheckedOutlinedIcon fontSize="small" />,
  assignee_added: <PersonOutlineOutlinedIcon fontSize="small" />,
  assignee_changed: <PersonOutlineOutlinedIcon fontSize="small" />,
  assignee_removed: <PersonOutlineOutlinedIcon fontSize="small" />,
  priority_changed: <TourOutlinedIcon fontSize="small" />,
  custom_field_updated: <RadioButtonCheckedOutlinedIcon fontSize="small" />,
  team: <PeopleAltOutlinedIcon fontSize="small" />,
  sub_team: <PeopleAltOutlinedIcon fontSize="small" />,
  issue_category: <FolderOpenOutlinedIcon fontSize="small" />,
  due_date_changed: <CalendarTodayOutlinedIcon fontSize="small" />,
  tag: <LocalOfferOutlinedIcon fontSize="small" />,
};

export const determineValueType = (value) => {
  // Improved URL check
  // Based on https://urlregex.com/ and simplified for common use cases
  const urlPattern =
    /^(https?:\/\/)?([a-zA-Z\d-]+(\.[a-zA-Z\d-]+)+)(\/[^\s]*)?$/;
  if (typeof value === "string" && urlPattern.test(value)) {
    return "url";
  }

  // Improved email check based on common use cases
  const emailPattern = /^[^\s@]+@[a-zA-Z\d-]+(\.[a-zA-Z\d-]+)+$/;
  if (typeof value === "string" && emailPattern.test(value)) {
    return "email";
  }

  // Basic dropdown check - this can be customized to your needs
  // For demonstration, this assumes dropdown means an array of options:
  if (Array.isArray(value)) {
    return "dropdown";
  }

  // Fallback to text for unhandled types
  return "text";
};

export const customFieldsIconsMap = {
  url: <LanguageIcon fontSize="small" />,
  dropdown: <ArrowDropDownSharpIcon fontSize="small" />,
  text: <TextFieldsIcon fontSize="small" />,
  email: <MailOutlineOutlinedIcon fontSize="small" />,
};

export const customFieldsIcons = {
  "L1 escalation notes": <TextFieldsIcon fontSize="small" />,
  "L2 escalation notes": <TextFieldsIcon fontSize="small" />,
  "Ide url": <LanguageIcon fontSize="small" />,
  "Ninja email id": <MailOutlineOutlinedIcon fontSize="small" />,
  "Pm dashboard url": <LanguageIcon fontSize="small" />,
  "Sensei email id": <MailOutlineOutlinedIcon fontSize="small" />,
  "Infra pipeline url": <LanguageIcon fontSize="small" />,
};

export const sanitizedHtml = (data) =>
  sanitizeHtml(data, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(["img"]),
    allowedAttributes: {
      ...sanitizeHtml.defaults.allowedAttributes,
      img: ["src", "width"],
      p: ["style", "data-block-id"],
    },
  });
