import { useParams } from 'react-router-dom';
import TicketsDetails from "../../components/TicketUI";
import { useState, useEffect, useRef } from "react";
import { REACT_APP_API_PATH as baseURL } from "../../config";
import { customFetch } from "../../services/requestServices";

function TicketsDetailsPage () {
  let {ticket_id } = useParams();
  const [ticketData, setTicketData] = useState([]);
  const [activityData, setActivityData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingLogs, setIsLoadingLogs] = useState(false);
  const [url] = useState(`${baseURL}v1/ticket/${{ticket_id}.ticket_id}`);
  const [activityUrl, setActivityUrl] = useState(
    `${baseURL}v1/task_audit/task_activity_logs/?ticket_id=${{ticket_id}.ticket_id}&limit=10`
  );
  const teamsController = useRef(null);
  const prevFetchUrl = useRef(null);

  const fetchTicketData = async (url) => {
    const fetchUrl = url;
    if (fetchUrl === prevFetchUrl.current || !fetchUrl) return;
    prevFetchUrl.current = fetchUrl;
    setIsLoading(true);
    try {
      const data = await customFetch(fetchUrl, "GET", null);
      setTicketData(data);
      setIsLoading(false);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      teamsController.current = null;
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const fetchActivityData = async (customUrl) => {
    const fetchUrl = customUrl || activityUrl;
    if (fetchUrl === prevFetchUrl.current || !fetchUrl) return;
    prevFetchUrl.current = fetchUrl;
    setIsLoadingLogs(true);
    try {
      const data = await customFetch(fetchUrl, "GET", null);
      setActivityData([...activityData, ...data.results]);
      if (data?.next != null) setActivityUrl(data.next);
      else setActivityUrl("");
      setIsLoadingLogs(false);
    } catch (error) {
      console.error("Failed to fetch data:", error);
      // handle error appropriately, e.g., show a message to the user
    } finally {
      teamsController.current = null;
      setIsLoadingLogs(false);
    }
    setIsLoadingLogs(false);
  };

  useEffect(() => {
    fetchTicketData(url);
    fetchActivityData(activityUrl);
  }, []);

const loadActivityData=()=>{
  fetchActivityData(activityUrl);
}
  return (
    <TicketsDetails
      ticketData={ticketData}
      isLoading={isLoading}
      activityData={activityData}
      loadActivityData={loadActivityData}
      isLoadingLogs={isLoadingLogs}
   
    />
  
  );
};

export default TicketsDetailsPage;
