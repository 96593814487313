import DefaultAvatar from "../assets/avatar.svg";
import DefaultDueDate from "../assets/DefaultDueDate.svg";
import { updateDateToHumanFormat } from "./common_functions";
import {capitalizeFirstLetter} from "../utils/clearAllSelectedFilters"
import { FlagFilled } from '@carbon/icons-react';


export const handleInputChange = (value, updateHandleInputChange) => {
  updateHandleInputChange(value);
};

export const getSubFilterClassName = (filterType) => {
  if (filterType !== "date" && filterType !== "closedDate")
    return "filter_dropdown_child_options sub_filter_max_height";
  else return "filter_dropdown_child_options";
};

export const handleHideAllSuboptions = (
  filterOptions,
  handleSetFilterOptions
) => {
  handleSetFilterOptions(
    filterOptions.map((filterOption) => {
      return {
        ...filterOption,
        isHighlighted: false,
        showsuboptions: false,
      };
    })
  );
};

export const handleSelectedColumns = (
  selectedColumnName,
  handleSetSelectedColumn,
  selectedColumn
) => {
  if (
    selectedColumn.columnName === "" ||
    selectedColumn.columnName !== selectedColumnName
  ) {
    handleSetSelectedColumn({
      columnName: `${selectedColumnName}`,
      forward: true,
    });
  } else if (selectedColumn.columnName === selectedColumnName) {
    handleSetSelectedColumn({
      ...selectedColumn,
      forward: !selectedColumn.forward,
    });
  }
};

export const getShortDescription = (description) => {
  return description.length > 35
    ? description.slice(0, 35).concat("...")
    : description;
};

export const checkAssignee = (assignee, colour) => {
  if (!assignee) {
    return (
      <div
        style={{
          display: "flex",
          width: "32px",
          height: "32px",
          padding: "8px",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          borderRadius: "100px",
          border: "2px solid var(--0, #FFF)",
          background: "var(--Base-Purple-4, #7C4DFF)",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "12px",
            height: "12px",
            padding: "0.375px 1.125px",
            justifyContent: "center",
            alignItems: "center",
            flexShrink: "0",
          }}
        >
          <img alt={"no assignee"} src={DefaultAvatar} />
        </div>
      </div>
    );
  }

  const firstLetter = assignee.charAt(0);
  return (
    <div className="firstLetter" style={{ backgroundColor: `${colour}` }}>
      {firstLetter}
    </div>
  );
};

export const removeSpaceswithUnderScores = (selectedText) => {
  if (selectedText === "first response sla")
    return "first_response_sla_achieved";
  else if (selectedText.includes(" ") && selectedText !== "created on")
    return selectedText.replace(/ /g, "_");
  else if (selectedText === "created on") return "created_at";
  else return selectedText;
};

export const findCheckedStatusFromUrlParams = (tempArr, id, parentKey) => {
  return tempArr.includes(id) || tempArr.includes(parseInt(id));
};

export const showFiltersDropdown = () => {
  const elem = document.getElementsByClassName("filters_dropdown")[0];
  elem.classList.toggle("show");
};
export const checkPriority = (priority, lowercase) => {
  const formattedPriority = lowercase
    ? capitalizeFirstLetter(priority)
    : priority.toUpperCase();

    const flag_colors={
      "high":"var(--Base-Amber-2, #FFE57F)",
      "urgent":" var(--Base-Blue-2, #FFB3AB)",
      "normal":"var(--Base-Blue-2, #8C9EFF)",
      "low":"var(--Base-Grey-4, #E8E8E8)",
      "Normal":"var(--Base-Blue-2, #8C9EFF)",
      "High":"var(--Base-Amber-2, #FFE57F)",
      "Urgent":" var(--Base-Blue-2, #FFB3AB)",
      "Low":"var(--Base-Grey-4, #E8E8E8)",
    }
  {
    return (
      <div className="priority">
        <div className="priority_image">
          <FlagFilled size={18} style={{fill:flag_colors[priority]}}/>
        </div>
        <div>{formattedPriority}</div>
      </div>
    );
  } 

};

export const checkDueDate = (dueDate, slaStatus) => {
  if (!dueDate) {
    return <img alt={"due date"} src={DefaultDueDate} />;
  }

  return (
    <div className={slaStatus === "Breached" ? "dueDatePassed" : ""}>
      {updateDateToHumanFormat(dueDate)}
    </div>
  );
};
