import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import TourOutlinedIcon from "@mui/icons-material/TourOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import {
  updateDateToHumanFormatAuditLogs,
} from "../../utils/common_functions";

export const getTicketConfigDetails = (pageData) => {
  return [
    {
      id: "status",
      name: "Status",
      icon: RadioButtonCheckedOutlinedIcon,
      type: "text",
      value: pageData?.status?.toUpperCase(),
      listData: [],
    },
    {
      id: "issue_category",
      name: "Issue category",
      icon: FolderOpenOutlinedIcon,
      type: "text",
      value: pageData.issue_category,
      listData: [],
    },
    {
      id: "due_date",
      name: "Due date",
      icon: CalendarTodayOutlinedIcon,
      type: "text",
      value: updateDateToHumanFormatAuditLogs(pageData.due_date),
      listData: [],
    },
    {
      id: "assignee",
      name: "Assignee",
      icon: PersonOutlineOutlinedIcon,
      type: "assignee",
      value: pageData.assignee,
      listData: [
        {
          url: pageData.user_avatar,
          username: pageData.assignee,
          color_code : pageData.assignee_color_code
        },
      ],
    },
    {
      id: "priority",
      name: "Priority",
      icon: TourOutlinedIcon,
      type: "priority",
      value: pageData.priority,
      listData: [],
    },
    {
      id: "team",
      name: "Team",
      icon: PeopleAltOutlinedIcon,
      type: "text",
      value: pageData.team,
      listData: [],
    },
    {
      id: "tags",
      name: "Tags",
      icon: LocalOfferOutlinedIcon,
      type: "tag",
      value: pageData.tags,
      listData: [],
    },
    {
      id: "sub_team",
      name: "Sub team",
      icon: PeopleAltOutlinedIcon,
      type: "text",
      value: pageData.sub_team,
      listData: [],
    },
  ];
};
