import { Icon, Tooltip } from "@mui/material";

const UserIcon = ({ image_url, username,colorCode }) => {
  return (
    <Tooltip title={username}>
      {image_url ? (
        <Icon>
          <img src={image_url} alt="user" className="user_logo" />
        </Icon>
      ) : (
        <div
          style={{
            width: "2rem",
            height: "2rem",
            backgroundColor: `${colorCode}`,
            color: "white",
            borderRadius: "16px",
            textAlign: "center",
            lineHeight: "2rem",
          }}
        >
          {username?.charAt(0).toUpperCase()}

        </div>
      )}
    </Tooltip>
  );
};

export default UserIcon;
