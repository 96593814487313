import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Icon from "@mui/material/Icon";
import Typography from "@mui/material/Typography";
import {
  iconsMap,
  renderSwitch,
  sanitizedHtml,
} from "../../utils/TicketDetailsCommonFunction";

import { Grid2 as Grid } from "@mui/material";
import {
  updateDateToHumanFormatAuditLogs,
  extractAndCapitalize,
  extractAndCapitalizeAllWords,
  componentDecorator,
} from "../../utils/common_functions";
import Linkify from "react-linkify";

const avatarStyle = {
  height: "2rem",
  width: "2rem",
  borderRadius: "15px",
};

const initialsStyle = (colorCode) => ({
  width: "2rem",
  height: "2rem",
  backgroundColor: colorCode,
  color: "white",
  borderRadius: "16px",
  textAlign: "center",
  lineHeight: "2rem",
});

const GetUserAvatar = ({ avatar, firstname, colorCode }) => {
  const firstLetter = firstname.charAt(0).toUpperCase();
  const color = colorCode || "#BB86FC";
  return avatar ? (
    <img
      src={avatar}
      alt="User Avatar"
      referrerPolicy="no-referrer"
      style={avatarStyle}
    />
  ) : (
    <div style={initialsStyle(color)}>{firstLetter}</div>
  );
};

const textOptions = (event_type, reason = "") => {
  if (event_type == "status_changed") return " changed status to ";
  if (event_type == "priority_changed") return " set priority to ";
  if (event_type == "due_date_changed") return " set due date to ";
  if (event_type == "assignee_added") return " added assignee";
  if (event_type == "assignee_removed") return " removed assignee ";
  if (event_type == "assignee_changed") return " changed assignee ";
  if (event_type == "escalation_notes_added")
    return ` added ${reason.replace("added", "").trim()} escalation notes: `;
  if (event_type == "custom_field_updated") return ` updated custom field to `;
};

const formattedValue = (data) => {
  const formatAssignee = (assignee) =>
    assignee ? extractAndCapitalizeAllWords(assignee.split("@")[0]) : "";

  switch (data.event_type) {
    case "due_date_changed":
      return updateDateToHumanFormatAuditLogs(data.value);
    case "assignee_added":
    case "assignee_changed":
    case "assignee_removed":
      let assignee = data.value;
      let prev_assignee = data.previous_value;
      return formatAssignee(assignee) || formatAssignee(prev_assignee);
    case "status_changed":
    case "priority_changed":
      return extractAndCapitalize(data.value);
    default:
      return data.value;
  }
};

const textIconOptions = (event_type) => {
  if (event_type == "priority_changed") return "priority";
  return "text";
};

export const TicketAction = ({ data }) => {
  return (
    <Grid
      container
      key={data.created_at}
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "1rem",
        fontSize: "0.5rem",
        padding: "10px 10px",
      }}
    >
      <Grid
        size={9}
        item
        style={{
          display: "flex",
        }}
      >
        {iconsMap[data.event_type] && (
          <Icon
            sx={{
              marginRight: "0.5rem",
              marginTop: "-0.2rem",
              color: "rgba(103, 107, 126, 1)",
            }}
          >
            {iconsMap[data.event_type]}
          </Icon>
        )}
        <Grid>
          <Typography
            style={{
              marginBottom: "0.5rem",
              flex: "auto",
            }}
            sx={{ color: "text.secondary" }}
          >
            {extractAndCapitalizeAllWords(data.performed_by.split("@")[0])}{" "}
            {textOptions(data.event_type)}
          </Typography>

          <Grid
            style={{
              display: "flex",
            }}
          >
            <Typography sx={{ color: "text.secondary" }}>
              <Linkify componentDecorator={componentDecorator}>
                {renderSwitch(
                  textIconOptions(data.event_type),
                  formattedValue(data),
                  []
                )}
              </Linkify>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item size={3} sx={{ textAlign: "end" }}>
        <Typography
          sx={{
            color: "rgba(103, 107, 126, 1)",
            fontSize: "0.5rem",
          }}
        >
          {updateDateToHumanFormatAuditLogs(data.created_at)}
        </Typography>
      </Grid>
    </Grid>
  );
};

export const CommentCard = ({ data }) => {
  return (
    <Card className="comment_card">
      <CardContent>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="comment_box">
            <GetUserAvatar
              avatar={data.user_avatar.user_avatar}
              firstname={data.performed_by.split("@")[0]}
              colorCode={data.user_avatar.user_color}
            />
            <Typography
              sx={{
                fontWeight: 500,
                marginLeft: "0.8rem",
              }}
            >
              {extractAndCapitalizeAllWords(data.performed_by.split("@")[0])}
            </Typography>
          </div>
          <Typography
            sx={{
              color: "rgba(103, 107, 126, 1)",
            }}
          >
            {updateDateToHumanFormatAuditLogs(data.created_at)}
          </Typography>
        </div>
        <Linkify componentDecorator={componentDecorator}>
          <div
            dangerouslySetInnerHTML={{ __html: sanitizedHtml(data.value) }}
          />
        </Linkify>
      </CardContent>
    </Card>
  );
};
